<template>
<div>
    <div class="license-body" style="margin: auto; padding: 1rem; width: 850px; height: auto; background-color: #fff;">
        <div style="padding: 3px; border: 1px solid #444444;">
            <div style="padding: 3px; border: 2px solid #444444;">
                <div style="padding: 1.5rem; border: 1px solid #444444;">
                    <!-- license- header -->
                    <div style="text-align: center;">
                        <img style="max-width: 120px; margin-bottom: .5rem;" src="../../../../../assets/images/logo.png">
                        <p style="font-weight: 600; font-size: 16px;">গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</p>
                        <p style="font-weight: 600; font-size: 16px; margin-bottom: .5rem;">বাংলাদেশ চা বোর্ড</p>
                        <p style="">১৭১-১৭২, বায়েজীদ বোস্তামী রোড</p>
                        <p style="">নাসিরাবাদ, চট্টগ্রাম।</p>
                        <p style="font-weight: 600;">অনলাইন চা লাইসেন্স সনদ</p>
                        <p style="font-weight: 600; text-decoration: underline; margin-top: 1rem;">লাইসেন্স নাম্বার : ১২০১০৬৫২</p>
                    </div>
                    <!-- license body -->
                    <div style="margin-top: 3rem; text-align: center;">
                        <p>
                            এই মর্মেপ্রত্যয়ন করা যাচ্ছে যে, চা আইন ২০১৬ এর ধারা ৭(ড) এর আওতায় <strong>একাটেরা বাংলাদেশ লিমিটেড</strong> এর পক্ষে <strong>দেব আনন্দ পল</strong>
                            বাংলাদেশ চা বোর্ড এর <strong>বিডার</strong> লাইসেন্স প্রাপ্ত হয়েছেন ।
                        </p>
                        <p style="font-weight: 600; margin: 2rem 0;">লাইসেন্সধারীর বিবরণঃ</p>
                        <ol style="list-style-type: bengali; list-style-position: inside; text-align: left; margin-left: 1.5rem;">
                            <li>লাইসেন্সধারী প্রতিষ্ঠানের নাম : <strong>একাটেরা বাংলাদেশ লিমিটেড</strong></li>
                            <li>মালিক/ব্যবস্থাপনা পরিচালকের নাম : <strong>দেব আনন্দ পল</strong></li>
                            <li>আবেদনকারীর ঠিকানা : <strong>৮৩ নাসিরাবাদ শিল্প এলাকা , ৮৩ নাসিরাবাদ শিল্প এলাকা ,বায়েজিদ বোস্তামি , চট্টগ্রাম</strong></li>
                            <li>জাতীয় পরিচয়পত্রের নাম্বার : <strong>জেড ৫৬৫৮৯২৯</strong></li>
                            <li>লাইসেন্স ইস্যুর তারিখ : <strong> ১৭/৪/২০২৩</strong></li>
                        </ol>
                        <p style="font-weight: 600; margin-top: 1rem;">মেয়াদ উত্তীর্ণের তারিখ : ৩০ জুন,২০২৩</p>
                    </div>
                    <!-- license footer -->
                    <table style="margin-top: 3rem;">
                        <tr>
                            <td style="width: 33.33%;">
                                <div>
                                    <strong>শর্তাবলী:</strong>
                                    <ol style="list-style-type: bengali; list-style-position: inside; font-size: 11px;">
                                        <li>
                                            প্রতি বছর ৩০শে জুনের মধ্যে লাইসেন্স নবায়ন করতে হবে অন্যথায় বিলম্ব ফী প্রদান করতে হবে।
                                            পরপর তিন বছর নবায়ন না করলে লাইসেন্সটি স্বয়ংক্রিয়ভাবে বাতিল হয়ে যাবে।
                                        </li>
                                        <li>
                                            লাইসেন্স এর কোন শর্ত ভঙ্গ করলে বাংলাদেশ চা বোর্ডযে কোন মুহূর্তেলাইসেন্স বাতিল করার ক্ষমতা সংরক্ষন করে।
                                        </li>
                                        <li>
                                            এ লাইসেন্স দ্বারা নিজস্ব ব্র্যান্ডে চা প্যাকেট করা যাবে না।
                                            নিজস্ব ব্র্যান্ডে চা প্যাকেটজাত করতে হলে বাংলাদেশ চা বোর্ড হতে ব্লেন্ডিং লাইসেন্স গ্রহন করতে হবে।
                                        </li>
                                    </ol>
                                </div>
                            </td>
                            <td style="width: 33.33%; text-align: center;">
                                <div>
                                    <img style="max-width: 130px;" src="../../../../../assets/images/qr.jpg" alt="">
                                </div>
                            </td>
                            <td style="width: 33.33%;">
                                <div>
                                    <img style="max-width: 80px;" src="../../../../../assets/images/signature.png">
                                    <p style="font-weight: 600;">মুহাম্মদ মদহুল কবীর চৌধুর</p>
                                    <p style="font-size: 12px;">উপ-পরিচালক (বাণিজ্য)</p>
                                    <p style="font-size: 12px;">বাংলাদেশ চা বোর্ড</p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data () {}
}
</script>
<style>
.license-body{
    background-image: url('../../../../../assets/images/btb-watermark.png');
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
